import {getNamesFromArray} from "@/utils/formData";

export default {
  countCells: 11,
  massive: [
    {
      dataField: 'nameOlympic',
      cols: 1,
      rows: 1,
      text: 'Олимпиада',
      class: 'd-flex flex-column align-start justify-start ',
      headerClass: 'd-flex flex-row',
      headerStyles: `min-width: 240px; justify-content: flex-start;`,
      order: 0,
      variable: true,
      headerTooltip: true,
      headerTooltipText: () => `Полное наименование олимпиады в соответствии с утвержденным положением об олимпиаде`,
      template: (r) => r?.olympiad?.name,
      rowsDataCalc: (r) => (r.profiles?.length ?? 1)
    },
    // {
    //   dataField: 'profiles',
    //   cols: 1,
    //   rows: 1,
    //   order: 2,
    //   variable: true,
    //   headerClass: ' ',
    //   class: 'px-2 d-flex flex-column justify-center',
    //   headerStyles: `min-width: 200px`,
    //   text: `Наименования(-е) профилей(-я) олимпиады`,
    //   rowsDataCalc: (r) => r.profiles?.length ?? 1
    // },
    {
      dataField: 'profile',
      cols: 1,
      rows: 1,
      order: 2,
      variable: true,
      headerClass: ' ',
      class: 'px-2 align-start',
      headerStyles: `min-width: 200px`,
      text: `Профиль`,
      // headerTooltip: true,
      // headerTooltipText: () => `                Профиль олимпиады`,
      template: (r) => r?.profile?.name ?? r.profile?.profile?.name,
    },
    {
      dataField: 'level',
      cols: 1,
      rows: 1,
      order: 3,
      class: 'd-flex flex-row align-start justify-center ',
      headerClass: 'd-flex flex-row',
      variable: true,
      headerTooltip: true,
      headerTooltipText: () => `Уровень олимпиады по указанному профилю`,
      text: 'Уровень',
      template: (row) => row.level
    },
    {
      dataField: 'actions',
      cols: 1,
      rows: 1,
      order: 11,
      class: 'd-flex flex-row align-start justify-center ',
      headerClass: '',
      variable: true,
      text: 'Данные по профилю',
      // rowsDataCalc: (r) => r.profiles?.length ?? 1
    },
    /*  deleted by new word fixes
    {
        dataField: 'dates',
        cols: 1,
        rows: 1,
        order: 3,
        headerClass: '',
        class: `align-start`,
        variable: true,
        template: (r) => r.schoolOlympiadProfileDataDto?.dates?.join(' - '),
        text: `Период проведения олимпиады`,
        headerTooltip: true,
        headerTooltipText: () => `Укажите даты начала первого этапа и завершения последнего этапа олимпиады`,
      },

      {
        dataField: 'actualProfile',
        cols: 1,
        rows: 1,
        text: `Проводилась ли олимпиада?`,
        class: `align-start`,
        headerClass: 'd-flex flex-row justify-center px-2',
        headerStyles: ``,
        headerTooltip: true,
        headerTooltipText: () => `Проводилась ли олимпиада по указанному профилю проводилась в 2020-2021
            учебном году?`,
        order: 3,
        template: (r) => getStringFromBoolean(r.schoolOlympiadProfileDataDto?.actualProfile),
        variable: true,
      },
      */
    {
      dataField: 'foreignStates',
      cols: 1,
      rows: 1,
      order: 4,
      class: `align-start`,
      headerClass: 'd-flex flex-row justify-center px-2',
      variable: true,
      headerTooltip: true,
      headerTooltipText: () => `Наименования иностранных государств, на территориях которых очно проводилось не менее одного этапа олимпиады по указанному профилю.`,
      text: 'Иностранные государства, где очно проводилось не менее одного этапа.',
      template: (row) => {
        return getNamesFromArray(row.schoolOlympiadProfileDataDto?.foreignStates, 'name', ', ', '')
      }
    },
    {
      dataField: 'foreignLanguages',
      cols: 1,
      rows: 1,
      order: 5,
      class: 'px-2 align-start',
      headerClass: 'd-flex flex-row justify-center px-2',
      variable: true,
      headerTooltip: true,
      headerTooltipText: () => `Наименования иностранных языков олимпиадных заданий по указанному профилю`,
      text: 'Иностранные языки олимпиадных заданий',
      template: (row) => {
        return getNamesFromArray(row.schoolOlympiadProfileDataDto?.foreignLanguages, 'name', ', ', 'Олимпиадные задания по указанному профилю составлялись только на русском языке')
      }
    },
    {
      dataField: 'foreignCitizens',
      cols: 1,
      rows: 1,
      order: 6,
      class: 'px-2 align-start',
      headerClass: 'd-flex flex-row justify-center px-2',
      variable: true,
      headerTooltip: true,
      headerTooltipText: () => `Наименования иностранных государств, граждане которых приняли участие в олимпиаде по указанному профилю.`,
      text: 'Иностранные государства, граждане которых приняли участие.',
      template: (row) => {
        return getNamesFromArray(row.schoolOlympiadProfileDataDto?.foreignCitizens)
      }
    },
    {
      dataField: 'numberParticipants',
      cols: 1,
      rows: 1,
      order: 7,
      class: `align-start`,
      headerClass: 'd-flex flex-row',
      variable: true,
      text: 'Общая численность участников',
      headerTooltip: true,
      headerTooltipText: () => `Общая численность участников олимпиады по указанному профилю`,
      template: (row) => {
        return row.schoolOlympiadProfileDataDto?.numberParticipants
      }
    },
    {
      dataField: 'foreignParticipants',
      cols: 1,
      rows: 1,
      order: 8,
      class: `align-start`,
      headerClass: '',
      variable: true,
      text: 'Из них иностранных граждан',
      template: (row) => {
        return row.schoolOlympiadProfileDataDto?.foreignParticipants
      }
    },
    {
      dataField: 'numberWinners',
      cols: 1,
      rows: 1,
      order: 9,
      class: 'px-2 align-start',
      headerClass: 'd-flex flex-row justify-center px-2',
      variable: true,
      headerTooltip: true,
      headerTooltipText: () => `Общая численность победителей и (или) призёров заключительного (итогового) этапа олимпиады по указанному профилю`,
      text: 'Общая численность победителей и (или) призёров заключительного этапа',
      template: (row) => {
        return row.schoolOlympiadProfileDataDto?.numberWinners
      }
    },
    {
      dataField: 'foreignWinners',
      cols: 1,
      rows: 1,
      order: 10,
      class: `align-start`,
      headerClass: '',
      variable: true,
      text: 'Из них иностранных граждан',
      // template: (row) => {
      //   return row.schoolOlympiadProfileDataDto?.foreignWinners
      // },
      template: (row) => row.schoolOlympiadProfileDataDto?.foreignWinners ? row.schoolOlympiadProfileDataDto?.foreignWinners : '0'
    },

  ]
}
